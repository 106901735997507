import { $ } from '../utils/dom';

const navigationOpenButton = $('.button--navigation');
const navigationNav = $('.navigation');

function addNavActiveClasses() {
  navigationNav.classList.toggle('is-active');
  navigationOpenButton.classList.toggle('is-active');
}

if (navigationOpenButton) {
  navigationOpenButton.addEventListener('click', addNavActiveClasses);
}
